<template>
    <div class="top-nav">
        <div class="container">
            <div class="pull-left">
                <p
                    class="top-tips"
                    style="margin-left:0;"
                    v-if="basedata"
                >
                    {{websitInfo.webkitData.site_name}} {{websitInfo.webkitData.site_summry}}
                    <span>服务热线：{{websitInfo.webkitData.site_phone | phoneFormatMethd('-')}}</span>
                </p>
            </div>
            <div class="pull-right right-menu-wrap">
            <!--
                <div style="float:right;font-size: 20px;margin-left: 20px; color: red;width:300px;height:36px;overflow:hidden;">
                    <marquee
                        direction="left"
                        scrollamount="2"
                    >询龙网2020年9月6日正式上线！欢迎入驻！</marquee>
                </div> -->
                <div class="download">
                    <a href="javascript:void(0)">
                        <!--询龙网APP -->
                        关注公众号
                        <i class="el-icon-caret-bottom"></i>
                    </a>
                    <em class="arrow"></em>
                    <div class="download-down">
                        <ul class="ewm-items download-hd">
                            <li
                                class="item"
                                @mouseover="hoverDown('wechat')"
                            >
                                <span class="tips">
                                    询龙网公众号
                                </span>
                            </li>
                            <li
                                class="item"
                                @mouseover="hoverDown('android')"
                            >
                                <span class="tips">
                                    安卓APP下载
                                </span>
                            </li>
                        </ul>
                        <div class="download-bd">
                            <!-- <div class="ewm-pic" v-if="showEr=='iphone'"> -->
                            <!-- 苹果二维码扫码下载 -->
                            <!-- <img :src="util.reImg(websitInfo.webkitData.apple_qr)" /> -->
                            <!-- </div>-->
                            <div class="ewm-pic" v-if="showEr=='android'">
                                <!-- 安卓二维码扫码下载  -->
                                <img :src="util.reImg(websitInfo.webkitData.android_qr)" />
                            </div>
                            <div class="ewm-pic" v-if="showEr=='wechat'">
                                <!-- 安卓二维码扫码下载  -->
                                <img :src="util.reImg(websitInfo.webkitData.site_wechat)" />
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="top-menu">
                    <li
                        class="menu-item login-item"
                        v-if="!userInfo.info.uk"
                    >
                        <a
                            href="javascript:void(0)"
                            @click="goLogin"
                        >请登录</a>
                        <a
                            href="javascript:void(0)"
                            class="reg"
                            @click="regClick"
                        >免费注册</a>
                    </li>
                    <li
                        class="menu-item"
                        v-if="userInfo.info.uk"
                    >
                        <div class="download d-userinfo">
                            <a href="javascript:void(0)">
                                会员中心
                                <i class="el-icon-caret-bottom"></i>
                            </a>
                            <em class="arrow"></em>
                            <div class="download-down">
                                <div class="down-ufo-wraper">
                                    <div class="duw-hd">
                                        <div class="duw-hd-l">
                                            <el-image :src="util.reImg(userInfo.info.avatar)">
                                                <div
                                                    slot="error"
                                                    class="image-slot"
                                                >
                                                    <i class="el-icon-picture-outline"></i>
                                                </div>
                                            </el-image>
                                        </div>
                                        <div class="duw-hd-r">
                                            <div class="duw-hd-r-row">
                                                <el-link
                                                    class="fl-r"
                                                    :underline="false"
                                                    @click="go_Logingout(0)"
                                                >退出</el-link>
                                                <h4 class="duw-tit">
                                                    <template v-if="userInfo.info.attestation != 2">
                                                        <el-tag v-text="userInfo.info.attestation_value"></el-tag>
                                                    </template>
                                                    <template v-else>
                                                        <el-tag v-if="userInfo.info.user_type==1">{{userInfo.info.attestation_type_value}}极客</el-tag>
                                                        <el-tag v-else>{{userInfo.info.attestation_type_value}}雇主</el-tag>
                                                    </template>
                                                    {{userInfo.info.name}}
                                                </h4>
                                            </div>
                                            <div class="duw-hd-r-row">
                                                <span class="coupon">
                                                    点券余额
                                                    <b>{{userInfo.info.points_surplus}}</b>
                                                </span>
                                                <h4 class="duw-tit duw-money">
                                                    <el-link href="/user/wallet/list">我的账户钱包</el-link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="duw-bd">
                                        <ul>
                                            <li>
                                                <el-link
                                                    :underline="false"
                                                    href="/user/index"
                                                >
                                                    <i class="item-icon el-icon-user"></i>
                                                    <p class="item-tit">用户中心</p>
                                                </el-link>
                                            </li>
                                            <li>
                                                <el-link
                                                    :underline="false"
                                                    href="/user/task/list?status=0"
                                                >
                                                    <i class="item-icon el-icon-document-copy"></i>
                                                    <p class="item-tit">我的项目</p>
                                                </el-link>
                                            </li>
                                            <li>
                                                <el-link
                                                    :underline="false"
                                                    href="/user/message?id=2"
                                                >
                                                    <i class="item-icon el-icon-chat-line-square"><span style="right: -5px;background-color: #F56C6C;border-radius: 10px;color: #FFF;display: inline-block;font-size: 12px;height: 18px;line-height: 18px;padding: 0 6px;text-align: center;white-space: nowrap;border: 1px solid #FFF;">{{this.userInfo.info.new_message_count}}</span></i>
                                                    <p class="item-tit">站内消息</p>
                                                </el-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="menu-item help-item">
                        <a
                            href="javascript:;"
                            style="cursor:pointer"
                            @click="go_help"
                        >帮助中心</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "TopNav",
    data() {
        return {
            basedata: {},
            showEr: "android" // 显示安卓或者ios的二维码
        };
    },
    methods: {
        ...mapActions("userInfo", ["loginOut"]),
        goLogin() {
            this.$router.push("/login/index?type=login");
        },

        go_Logingout(n) {
            //退出登录
            this.loginOut();
            if (n == 1) {
                this.$router.push("/login/index?type=login");
            } else {
                this.$message.error("退出登录成功");
                this.$router.push("/");
            }
        },

        go_to(name, params) {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.$router.push({ name: name, params: params });
        },

        //跳转到消息列表
        goMessageList() {
            if (this.userInfo.info.new_message_count > 0) {
                this.$router.push("/user/message?id=3");
            } else {
                this.$router.push("/user/message?id=2");
            }
        },

        hoverDown(str) {
            this.showEr = str;
        },

        //注册按钮
        regClick() {
            this.$router.push("/login/index?type=register");
        },

        //跳转到帮助中心页面
        go_help() {
            this.$router.push("/help/index/12");
        }
    },

    computed: {
        ...mapState(["userInfo"]),
        ...mapState(["websitInfo"])
    }
};
</script>


