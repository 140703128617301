<template>
    <div class="footer">
        <div class="container">
            <div class="top">
                <div class="col1">
                    <div class="logo">{{websitInfo.webkitData.site_name}}</div>
                    <div class="logo-url">{{websitInfo.webkitData.site_domain}}</div>
                    <div class="phone-name">全国服务热线</div>
                    <div class="phone-num">{{websitInfo.webkitData.site_phone | phoneFormatMethd('-')}}</div>
                    <ul class="info">
                        <li>
                            QQ
                            <span style="margin-right:0px;"></span>
                            客服：
                            {{websitInfo.webkitData.qq}}
                        </li>
                        <li>
                            工
                            <span style="margin-right:2px;"></span>作
                            <span style="margin-right:2px;"></span>
                            日：{{websitInfo.webkitData.work_day}}
                        </li>
                        <li>
                            邮
                            <span style="margin-right:22px;"></span>
                            箱：{{websitInfo.webkitData.site_email}}
                        </li>
                        <li>
                            地
                            <span style="margin-right:22px;"></span>
                            址：{{websitInfo.webkitData.site_address}}
                        </li>
                    </ul>
                </div>

                <div class="col2 col">
                    <div class="title">快速入口</div>
                    <ul class="list">
                        <li class="item">
                            <a href="/article/index">资料分享</a>
                        </li>
                        <li class="item">
                            <a href="/task/list">找技术项目</a>
                        </li>
                        <li class="item">
                            <a href="/geek/list">找工程极客</a>
                        </li>
                    </ul>
                </div>

                <div class="col3 col">
                    <div class="title">关于我们</div>
                    <ul class="list">
                        <li class="item">
                            <a href="/about/index">网站介绍</a>
                        </li>
                        <li class="item">
                            <a href="/contact/index">联系我们</a>
                        </li>
                        <!-- <li class="item">
                            <a href="/help/index/26">平台公告</a>
                        </li>-->
                    </ul>
                </div>

                <div class="col4 col">
                    <div class="title">协议规则</div>
                    <ul class="list">
                        <li class="item">
                            <a href="/protocol/index/2">用户服务协议</a>
                        </li>

                        <li class="item">
                            <a href="/protocol/index/3">平台交易规则</a>
                        </li>

                        <li class="item">
                            <a href="/protocol/index/430">询龙网隐私保护政策</a>
                        </li>
                    </ul>
                </div>

                <div class="col5 col">
                    <div class="title">帮助中心</div>
                    <ul class="list">
                        <li
                            class="item"
                            v-for="(help,index) in help_center"
                            :key="index"
                        >
                            <a @click="goHelp(help.id)">{{help.title}}</a>
                        </li>
                    </ul>
                </div>

                <div class="col6 col">
                    <div class="title">下载中心</div>
                    <ul class="list">
                        <!-- <li class="item">APP下载</li> -->
                        <li class="item">微信公众号</li>
                        <li class="item">
                            <el-image
                                class="wx"
                                :src="util.reImg(websitInfo.webkitData.site_wechat)"
                            ></el-image>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="bottom">
                <div class="link">
                    <div class="title">友情链接：</div>
                    <ul class="list link-list">
                        <li
                            class="item"
                            v-for="(item,index) in websitInfo.links"
                            :key="index"
                        >
                            <a
                                target="_blank"
                                :href="util.reImg(item.from_url)"
                            >{{item.title}}</a>
                        </li>
                    </ul>
                </div>

                <div class="copyright">
                    <ul class="list">
                        <li class="item">{{websitInfo.webkitData.site_address}}</li>
                        <li class="item">{{websitInfo.webkitData.site_icp}}</li>
                        <li
                            class="item"
                            v-html="websitInfo.webkitData.site_statis"
                        ></li>
                    </ul>
                </div>

                <div class="copyright" style="margin-top:10px">
                    <ul class="list">
                        <li class="item">
                            <span>增值电信业务经营许可证 京B2-20203299 | </span>
                            <a
                                target="_blank"
                                href="https://beian.miit.gov.cn/#/Integrated/index"
                            >京ICP备19033951号-1</a>
                            <span> | 京公网安备11010802033495号</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "GlobalFooter",
    data() {
        return {
            help_center: []
        };
    },
    created() {
        //如果网站信息没有存入store，则获取网站基本信息
        // if (!this.websitInfo.webkitData) {
        this.acUpdataWebSit();
        // }

        // if (!this.websitInfo.links) {
        this.updateLines();
        // }
        this.getProNav();
    },
    computed: {
        ...mapState(["websitInfo"])
    },
    methods: {
        //获取分类
        getProNav() {
            let _this = this;
            this.post("/article/index/help_center", {}, function (res) {
                if (res.code == 200) {
                    _this.help_center = res.data[12].list;
                }
            });
        },
        goHelp(id) {
            this.$router.push("/help/index/12?id=" + id);
        },
        ...mapActions("websitInfo", ["acUpdataWebSit", "updateLines"]) //刷新用户数据方法
    }
};
</script>

<style lang="less" scoped>
@import "../../styles/index.less";
</style>



